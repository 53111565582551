
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--marketplaceGreen);
    border-radius: 25px;
    color: var(--matterColor);
    height: 50px;
    padding: 0 10px;
    min-width: 100px;
    border: none;
    cursor: pointer;
    &:hover{
      background-color: var(--marketplaceHoverGreen);
    }
  